.title {
  @apply font-pacifico text-center pt-16 leading-none text-pink-500;
  font-size: 8rem;
  text-shadow: 2px 2px 5px rgba(238, 15, 121, 0.22);
}

.dayItemLink {
  @apply mt-5 rounded-lg px-6 py-6 text-pink-50 shadow-lg transform hover:scale-110 transition-all duration-500 ease-in-out;
  width: 600px;
  text-shadow: 1px 1px 5px rgba(134, 0, 63, 0.22);
}

.navBtn {
  @apply flex bg-pink-500 text-white p-4 rounded-full items-center hover:bg-pink-400 shadow-md;
}

.prevBtn {
  position: fixed;
  top: calc(50% - 25px - 1rem);
  left: calc((100% / 2) - 500px);
}

.nextBtn {
  position: fixed;
  top: calc(50% - 25px - 1rem);
  right: calc((100% / 2) - 500px);
}
