.title {
  @apply font-pacifico text-center pt-16 leading-none text-pink-500;
  font-size: 8rem;
  text-shadow: 2px 2px 5px rgba(238, 15, 121, 0.22);
}

.subtitle {
  @apply text-center text-pink-500 mt-10;
}

.songCalendarLink {
  @apply bg-pink-500 text-white rounded-2xl px-5 py-3 shadow-md;
}

.songCalendarLink:hover {
  @apply bg-pink-400;
}
