.container {
  @apply bg-yellow-50 fixed inset-0 h-full w-full;
  z-index: -10;
}

.canvas {

}

.content {
  @apply font-barlow;
  min-width: 1200px;
}
